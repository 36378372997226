@import '../../../../variables';

.sectionSix {
   background: $background-3a;
   .content {
      h6 {
         font-size: 1.17rem;
         color: $color-1;
      }
      h1 {
         font-size: 3.05rem;
         color: $color-1;
      }
      // .banner {}
      .learnMore {
         font-size: .9rem;
         color: $background-1;
         padding: .7rem 1.7rem;
         border-radius: 3px;
         border: 1px solid $background-1;
         transition: $trans3;
         &:hover {
            border: 1px solid transparent;
            background: $background-1a;
            color: $white;
         }
      }
      @media screen and (max-width: 767px) {
         h6 {
            font-size: .9rem;
         }
         h1 {
            font-size: 2rem;
         }
         .banner {
            .trendingNews {
               --height: 22rem;
               .blogPost {
                  .content {
                     .title {
                        font-size: .92rem;
                     }
                     .post {
                        width: 100%;
                        font-size: .85rem;
                        // line-height: 1.3;
                     }
                     button, .date {
                        font-size: .7rem;
                     }
                     button {
                        padding: .3rem .6rem;
                     }
                  }
               }
            }

         }
      }
   }
}
