@import '../../../../variables';

.aboutSectionThree {
   background: $background-3;
   .content {
      .img {
         height: 31.38rem;
         width: 28.5rem;
         position: relative;
         border-radius: 4px;
         &::before {
            content: '';
            position: absolute;
            top: 1rem;
            right: -1rem;
            width: 80%;
            height: 100%;
            border-radius: 4px;
            background: $background-4;
            z-index: -1;
         }
         img {
            border-radius: 4px;
         }
         .iconWrapper {
            left: -3.8rem;
            bottom: 26%;
            .starIcon {
               transform: scale(.7);
            }
         }
      }
      p {
         font-size: 1.07rem;
         color: $color-3;
         line-height: 2.7;
      }
   }
   @media screen and (max-width: 767px) {
      .content {
         .img {
            height: 25.5rem;
            width: 21.61rem;
            .iconWrapper {
               .starIcon {
                  transform: scale(.5);
               }
            }
         }
         p {
            font-size: 1rem;
            line-height: 2;
         }
      }
   }
}
