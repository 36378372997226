@import '../../../../variables';

.sectionTwo {
   background: $background-3;
   .wlcmBg {
      left: 4rem;
      top: 14%;
      width: 47rem;
      z-index: 1;
   }
   .content {
      .banner {
         z-index: 2;
      }
      .img-1 {
         height: 33.3rem;
         width: 18.74rem;
         margin-top: 4.2rem;
         img {
            border-radius: 5px;
         }
         .iconWrapper {
            top: 47.5%;
            left: -5.8rem;
            .icon {
               transform: scale(.72);
            }
         }
      }
      .img-2 {
         height: 33.3rem;
         width: 15.74rem;
         img {
            border-radius: 5px;
         }
         .iconWrapper {
            top: 3.5%;
            right: -1.9rem;
            .icon {
               transform: scale(.7);
            }
         }
      }
      .welcome {
         padding-left: 6.25rem;
         padding-right: 2rem;
         h6 {
            font-size: 1.17rem;
            color: $color-1;
         }
         h1 {
            font-size: 3.15rem;
            color: $color-1;
         }
         p {
            font-size: 1.17rem;
            color: $color-3;
            line-height: 2;
            text-align: justify;
         }
         .about {
            font-size: .9rem;
            color: $white;
            padding: .7rem 2.5rem;
            border-radius: 3px;
            background: $background-1;
            transition: $trans3;
            &:hover {
               background: $background-1a;
            }
         }
      }
   }
   @media screen and (max-width: 767px) {
      .content {
         .img-1, .img-2 {
            height: 27.3rem;
         }
         .welcome {
            h6 {
               font-size: .9rem;
            }
            h1 {
               font-size: 2rem;
            }
            p {
               font-size: 1rem;
            }
         }
      }
   }
   @media screen and (max-width: 1223px) {
      .content {
         .welcome {
            padding-left: 0;
            padding-right: 0;
         }
      }
   }
}