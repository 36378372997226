@import '../../../../variables';

.sectionOne {
   .healthyBg {
      right: 0;
      bottom: -2rem;
      height: 37.1rem;
      // width: 30rem;
   }
   .content {
      .intro {
         font-size: 3.9rem;
         font-weight: 600;
         color: $color-1;
         line-height: 1.2;
         span {
            color: $background-1;
         }
         @media screen and (max-width: 991px) {
            font-size: 3.2rem;
         }
      }
      .banner {
         .desc {
            font-size: 1.2rem;
            color: $color-3;
            line-height: 1.5;
         }
         .reservations {
            font-size: .9rem;
            color: $white;
            padding: .6rem 1.7rem;
            background: $background-1;
            border-radius: 3px;
            transition: $trans3;
            &:hover {
               background: $background-1a;
            }
         }
         .img-1 {
            margin-top: 2.7rem;
            height: 17.5rem;
            img {
               border-radius: 5px 5px 0 0;
            }
            .iconWrapper {
               top: -2.47rem;
               right: 27%;
               .icon {
                  transform: scale(.7);
               }
            }
         }
         .img-2 {
            // height: 25.3rem;
            height: 38.1rem;
            img {
               border-radius: 5px 5px 0 0;
            }
            .iconWrapper {
               top: -1.5rem;
               left: 4.6rem;
               .icon {
                  transform: scale(.7);
               }
            }
         }
         @media screen and (max-width: 991px) {
            .img-2 {
               height: 20rem;
            }
         }
      }
      .mobileView {
         width: 100%;
         height: fit-content;
         z-index: 200;

         &::-webkit-scrollbar {
            width: 0; /* Safari and Chrome */
            height: 0; /* Safari and Chrome */
         }
         &::-webkit-scrollbar-track {
            background: transparent;
         }
         &::-webkit-scrollbar-thumb {
            position: absolute;
            background-color: #383838;
            border-radius: 0;
         }
         .inner {
            display: flex;
            overflow-x: scroll;
            position: relative;
            img {
               width: 19rem;
               height: 17rem;
               border-radius: 5px 5px 0 0;
            }
            .image-1 {
               margin-right: 2.5rem;
            }
            .iconMobile {
               position: absolute;
               // top: 0;
            }
            .icon1 {
               top: -.06rem;
               left: 30%;
               transform: scale(.7);
            }
            .icon2 {
               left: -2.6rem;
               top: 3rem;
               transform: scale(.7);
            }
            // .img-1, .img-2 {
            //    // margin-top: 4.2rem;
            //    height: 17.5rem;
            //    width: 17rem;
            //    img {
            //       border-radius: 5px 5px 0 0;
            //    }
            //    .iconWrapper {
            //       top: -1.57rem;
            //       right: 40%;
            //       .icon {
            //          transform: scale(.7);
            //       }
            //    }
            // }
            // .img-2 {
            //    height: 25.3rem;
            //    width: 20rem;
            //    img {
            //       border-radius: 5px 5px 0 0;
            //    }
            //    .iconWrapper {
            //       top: 2.2rem;
            //       left: -2.6rem;
            //       .icon {
            //          transform: scale(.7);
            //       }
            //    }
            // }
         }
      }
   }
   @media screen and (max-width: 767px) {
      .content {
         .intro {
            font-size: 2rem;
         }
         .banner {
            .img-1 {
               margin-top: 2.5rem;
            }
         }
      }
   }
}