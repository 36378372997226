@import '../../variables';

.dishCards {
   .contents {
      // width: fit-content;
      width: 100%;
      .view {
         cursor: pointer;
         --width: 14.84rem;
         --height: 19rem;
         .img {
            height: var(--height);
            // width: var(--width);
            width: 100%;
            border-radius: 4px;
            overflow: hidden;
            background: $white;
            img {
               transition: $trans3;
            }
         }
         &:hover {
            img {
               transform: scale(1.05);
            }
         }
         .title, .desc {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
         }
         .title {
            font-size: 1.25rem;
            color: $color-1;
         }
         .desc {
            font-size: 1rem;
            color: $color-1;
            height: 25.594px;
         }
      }
      .low {
         // width: fit-content;
         span {
            color: $background-1;
            font-size: 1.5rem;
            font-weight: 600;
         }
         button {
            font-size: .82rem;
            color: $background-1;
            padding: .5rem 1rem;
            background: $background-3;
            border: 1px solid $background-1;
            border-radius: 4px;
            transition: $trans3;
            &:hover, &.addedToCart {
               border: 1px solid transparent;
               color: $white;
               background: $background-1;
            }
            &:hover {
               background: $background-1a;
            }
            &.addedToCart {
               &:hover {
                  background: $background-1;
               }
            }
         }
      }
   }
   @media screen and (max-width: 767px) {
      .content {
         .view {
            // --width: 11.67rem;
            --height: 15rem;
            .title {
               font-size: .95rem;
            }
            .desc {
               font-size: .85rem;
               height: 21.75px;
            }
         }
         span {
            font-size: 1.05rem;
         }
         button {
            font-size: .72rem;
            padding: .3rem .9rem;
         }
      }
   }
}
