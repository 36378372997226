@import '../../../../variables';

.aboutSectionOne {
   margin-top: -5rem;
   height: 39rem;
   // .bgOverlay {
   //    top: 0;
   //    left: 0;
   //    right: 0;
   //    bottom: 0;
   //    background: $background-5;
   //    z-index: -1;
   // }
   .img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
   }
   .content {
      z-index: 2;
      h1 {
         font-size: 2.6rem;
         font-weight: 600;
         color: $white;
         line-height: 1.8;
         position: relative;
         &::before {
            content: '';
            position: absolute;
            top: -2rem;
            left: 0;
            height: 8px;
            width: 12%;
            background: $background-4;
         }
      }
      @media screen and (max-width: 991px) {
         h1 {
            font-size: 2rem;
         }
      }
   }
}

@media screen and (max-width: 767px) {
   .aboutSectionOne {
      margin-top: -3.5rem;
      height: 60vh;
      .content {
         h1 {
            font-size: 1.4rem;
            line-height: 1.5;
            &::before {
               width: 20%;
               height: 5px;
               top: -1rem;
            }
         }
      }
   }
}
