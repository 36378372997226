@import '../../variables';

.__blog {
   // .blogTitle {
   //    font-size: 2rem;
   //    color: $color-1;
   // }
   .blogSubTitle {
      font-size: 1.2rem;
      color: $color-1;
   }
   .popularPosts {
      background: #FFFFFF;
      box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
      border-radius: 8px;
      p {
         font-size: 1.2rem;
         color: $color-1;
      }
      ul {
         &::-webkit-scrollbar {
            width: 0; /* Safari and Chrome */
            height: 0;
         }
         &::-webkit-scrollbar-track {
            // background: #1a1a1a;
            background: transparent;
         }
         &::-webkit-scrollbar-thumb {
            position: absolute;
            background-color: #383838;
            border-radius: 30px;
         }
         li {
            color: $color-1;
            background: $white;
            border: 1px solid $background-9;
            border-radius: 5px;
            cursor: pointer;
            transition: $trans3;
            &:hover, &.active {
               background: #0001;
            }
            .img {
               --value: 3rem;
               width: 3.6rem;
               height: var(--value);
               border-radius: 4px;
               overflow: hidden;
            }
            .label {
               p {
                  font-size: 1rem;
                  color: $color-3;
               }
               span {
                  font-size: .8rem;
                  color: $background-1;
               }
            }
         }
      }
      @media screen and (max-width: 767px) {
         ul {
            display: flex;
            align-items: center;
            overflow-x: scroll;
         }
      }
   }
   .readBlog {
      background: #FFFFFF;
      box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
      border-radius: 8px;
      .img {
         width: 100%;
         height: 19.76rem;
         border-radius: 8px;
         overflow: hidden;
      }
      h4 {
         font-size: 2rem;
         color: $color-1;
      }
      @media screen and (max-width: 767px) {
         .img {
            height: 12rem;
         }
         h4 {
            font-size: 1.5rem;
         }
      }
      .date, .commentsCount {
         display: flex;
         align-items: center;
         .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            --value: .8rem;
            width: var(--value);
            height: var(--value);
         }
         span {
            font-size: .85rem;
            color: $background-1;
         }
      }
      .post {
         color: $color-3;
         line-height: 2;
      }
      .tagging {
         .tags {
            font-size: 1.1rem;
            color: $color-3;
         }
         .tag {
            font-size: 1.04rem;
            color: $background-1;
         }
         .socialMedia {
            li {
               --value: 2rem;
               width: var(--value);
               height: var(--value);
               border-radius: 50%;
               overflow: hidden;
               border: 1px solid $color-1;
               background: transparent;
               display: flex;
               align-items: center;
               justify-content: center;
               transition: $trans3;
               cursor: pointer;
               &:hover {
                  border: 1px solid $background-1;
               }
               .icon {
                  color: $color-1;
                  transform: scale(.8);
                  transition: $trans3;
               }
               &:hover .icon {
                  color: $background-1;
               }
            }
         }
      }
      .comments {
         .allComments {
            li {
               border-bottom: 1px solid $color-4;
               .commentImg {
                  --value: 3rem;
                  width: var(--value);
                  height: var(--value);
                  border-radius: 50%;
                  overflow: hidden;
               }
               .name__star {
                  .name {
                     p {
                        font-size: 1.1rem;
                        color: $color-1;
                     }
                     small {
                        font-size: .8rem;
                        color: $color-5;
                     }
                  }
               }
               .comment {
                  color: $color-3;
                  text-align: justify;
               }
               .reply {
                  cursor: pointer;
                  .icon {
                     --value: .8rem;
                     width: var(--value);
                     height: var(--value);
                  }
                  span {
                     font-size: 1rem;
                     color: $background-1;
                  }
               }
            }
         }
         .dropComments {
            h4 {
               color: $color-1;
            }
            .__form {
               label {
                  font-size: 1rem;
                  color: $color-3;
               }
               input, textarea {
                  font-size: .9rem;
                  color: $color-1;
                  padding: .5rem 1.2rem;
                  border-radius: 4px;
                  border: 1px solid $color-4;
                  &::placeholder {
                     color: $color-4;
                  }
               }
               .submit {
                  font-size: .9rem;
                  color: $white;
                  padding: .7rem 2.5rem;
                  border-radius: 4px;
                  background: $background-1;
                  transition: $trans3;
                  &:hover {
                     background: $background-1a;
                  }
               }
            }
         }
      }
   }
}
