@import '../../../../variables';

.sectionFour {
   width: 100%;
   height: 13.3rem;
   background: $background-1;
   .statsBg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
   }
   .banner {
      .plaque {
         .icon {
            width: 3rem;
         }
         .figures {
            font-size: 2.2rem;
            font-weight: 600;
            color: $white;
         }
         .label {
            font-size: 1.17rem;
            color: $white;
         }
      }
   }
}

@media screen and (max-width: 767px) {
   .sectionFour {
      height: fit-content;
      padding-top: 2rem !important;
      // padding-bottom: 2rem !important;
      .banner {
         .plaque {
            padding-bottom: 2rem;
            .icon {
               width: 2rem;
            }
            .figures {
               font-size: 1.8rem;
            }
            .label {
               font-size: .9rem;
            }
         }
      }
   }
}
