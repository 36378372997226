@import '../../../../variables';

.aboutSectionFive {
   height: 33rem;
   .bgOverlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background: $background-5;
      z-index: -1;
   }
   .bgImg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
   }
   .content {
      z-index: 2;
      .title {
         font-size: 3rem;
         font-weight: 600;
         color: $white;
      }
      .bookTable {
         background: $background-7;
         color: $white;
         border-radius: 5px 5px 0px 0px;
      }
      .subTitle {
         font-size: 1.5rem;
         font-weight: 600;
         color: $white;
      }
      .hours, .eatIn {
         color: $white;
      }
      .day, .time, .freeDelivery, .delivery {
         font-size: .95rem;
         color: $white;
      }
      .reserve {
         font-size: .95rem;
         border: 1px solid  $white;
         padding: .7rem 1.5rem;
         border-radius: 4px;
         color: $white;
         transition: $trans3;
         &:hover {
            border: 1px solid  transparent;
            background: $white;
            color: $color-1;
         }
      }
   }
}

@media screen and (max-width: 767px) {
   .aboutSectionFive {
      height: 100%;
      .content {
         .title {
            font-size: 2rem;
         }
         .bookTable {
            width: 100%;
         }
      }
   }
}
