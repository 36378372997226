@import '../../../../variables';

.aboutSectionTwo {
   // background: $background-6;
   background: $background-11;
   .content {
      h1 {
         font-size: 2.4rem;
         color: $color-1;
         line-height: 1.6;
         position: relative;
         &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 1.5rem;
            height: 4px;
            width: 30%;
            background: $background-1;
         }
      }
      p {
         font-size: 1.07rem;
         line-height: 2.5;
         color: $color-3;
      }
      .teaCupIcon {
         position: absolute;
         right: 0;
         bottom: -1.5rem;
         transform: scale(.7);
      }
      .img {
         width: 36rem;
         height: 38.8rem;
         border-radius: 4px;
         overflow: hidden;
         // margin-top: 2rem;
      }
   }
   @media screen and (max-width: 991px) {
      .content {
         h1 {
            font-size: 2rem;
         }
      }
   }
   @media screen and (max-width: 767px) {
      .content {
         h1 {
            font-size: 1.7rem;
            &::before {
               top: 1rem;
               width: 25%;
            }
         }
         p {
            font-size: .9rem;
            line-height: 2;
         }
         .teaCupIcon {
            bottom: -1rem;
            transform: scale(.5);
         }
         .img {
            width: 21.55rem;
            height: 25rem;
         }
      }
   }
}
