@import '../../variables';

.menuSectionOne {
   height: 20.5rem;
   .bgOverlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $background-5;
      z-index: -1;
   }
   .img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
   }
   .content {
      z-index: 2;
      p {
         font-size: 2.6rem;
         font-weight: 600;
         color: $white;
         line-height: 1.8;
      }
   }
}

.menuSectionTwo {
   background: $background-3;
   .content {
      .categories {
         background: $color-2a;
         border: 1px solid $background-8;
         border-radius: 5px;
         .cat {
            font-size: 1.5rem;
            color: $color-1;
            cursor: pointer;
         }
         ul {
            &::-webkit-scrollbar {
               width: 0; /* Safari and Chrome */
               height: 0;
            }
            &::-webkit-scrollbar-track {
               // background: #1a1a1a;
               background: transparent;
            }
            &::-webkit-scrollbar-thumb {
               position: absolute;
               background-color: #383838;
               border-radius: 30px;
            }
            li {
               color: $color-1;
               background: $white;
               border: 1px solid $background-9;
               border-radius: 5px;
               cursor: pointer;
               transition: $trans3;
               &:hover, &.active {
                  background: #0001;
               }
               .img {
                  --value: 3rem;
                  width: 3.6rem;
                  height: var(--value);
                  border-radius: 4px;
                  overflow: hidden;
               }
               .label {
                  font-size: 1.1rem;
               }
               span {
                  font-size: 1rem;
                  background: #f5f5f5;
                  color: #606060;
                  border-radius: 15px;
                  padding: .1rem .7rem;
               }
            }
         }
         @media screen and (max-width: 767px) {
            ul {
               display: flex;
               align-items: center;
               overflow-x: scroll;
               li {
                  flex-shrink: 0;
                  margin-right: 1.5rem;
                  .label {
                     font-size: 1rem;
                  }
                  span {
                     font-size: 1rem;
                     padding-left: .4rem;
                  }
               }
            }
         }
      }
      .food {
         h3 {
            font-size: 2.3rem;
            color: $color-1;
         }
         p {
            font-size: 1.2rem;
            color: $color-1;
         }
         @media screen and (max-width: 767px) {
            h3 {
               font-size: 1.8rem;
            }
            p {
               font-size: .9rem;
            }
         }
      }
   }
}
