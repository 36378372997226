@import '../../variables';

.pagination {
   li {
      color: $color-1;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      .icon {
         transform: scale(.7);
      }
   }
}