@import '../../variables';

.dishWrapper {
   .prev, .next, .top {
      cursor: pointer;
      transition: $trans3;
      // position: absolute;
      // top: 50%;
      --value: 45px;
      height: var(--value);
      width: var(--value);
      border-radius: 50%;
      background-color: #FFF;
      box-shadow: 0px 14px 20px rgba(227, 229, 232, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
   }

   .prev {
      left: -10px;
      transform: scale(0.86);
      &:hover {
         transform: scale(0.95);
      }
      &.hidden {
         opacity: 0;
         pointer-events: none;
      }
   }

   .next {
      right: 10px;
      transform: scale(0.86);
      &:hover {
         transform: scale(0.95);
      }
      &.hidden {
         opacity: 0;
         pointer-events: none;
      }
   }

   .top {
      right: 10px;
      transform: scale(0.86) rotate(-90deg);
      &:hover {
         transform: scale(0.95) rotate(-90deg);
      }
      &.hidden {
         opacity: 0;
         pointer-events: none;
      }
   }

   .slick-list {
      // overflow: visible;
      .slick-track {
         // margin: 0;
         // width: fit-content;
         .slick-slide {
            &:not(:last-of-type) {
               // margin-right: .5rem;
            }
         }
      }
   }

   .caroosel {
      // overflow: hidden;
      width: 100%;
      height: fit-content;
      .caroosel-inner {
         --carousel-index: 0;
         display: flex;
         // flex-grow: 1;
         // margin: 0 .25rem;
         transform: translateX(calc(var(--carousel-index) * -30%));
         transition: .3s ease-in-out;
         &::-webkit-scrollbar {
            width: 0; /* Safari and Chrome */
            height: 0; /* Safari and Chrome */
         }
         &::-webkit-scrollbar-track {
            background: transparent;
         }
         &::-webkit-scrollbar-thumb {
            position: absolute;
            background-color: #383838;
            border-radius: 0;
         }
      }
      @media screen and (max-width: 767px) {
         .caroosel-inner {
            overflow-x: scroll;
            .dishCards {
               // margin-right: .5rem;
               width: 15rem !important;
            }
         }
      }
   }
}
