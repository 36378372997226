@import '../../variables';

.__cart {
   background: $background-3;
   .content {
      .inner {
         .cartItems, .cartTotals {
            background: $white;
            border-radius: 8px;
            box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
         }
         .cartItems {
            .img {
               --value: 3.5rem;
               width: var(--value);
               height: var(--value);
               border-radius: 4px;
               overflow: hidden;
               display: flex;
               align-items: center;
               justify-content: center;
               box-shadow: 0px 4px 25px rgba(155, 155, 155, 0.1);
            }
            .desc {
               font-size: 1.1rem;
               color: $color-1;
               line-height: 1.4;
            }
            .des {
               font-size: .75rem;
               color: $color-3;
               line-height: 1.3;
               -webkit-box-orient: vertical;
               display: block;
               display: -webkit-box;
               overflow: hidden !important;
               text-overflow: ellipsis;
               -webkit-line-clamp: 2;
               // @media screen and (max-width: 767px) {
               //    line-height: 1;
               // }
            }
            .quantity {
               .minus, .add {
                  --value: 1.5rem;
                  width: var(--value);
                  height: var(--value);
                  font-size: 1.5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  overflow: hidden;
                  background: $white;
                  border: 1px solid #212121;
                  transition: $trans3;
                  cursor: pointer;
                  &:hover {
                     color: $white;
                     background: #212121;
                     border: 1px solid transparent;
                  }
               }
               .numb {
                  font-size: 1.3rem;
                  font-weight: 600;
                  width: 35px;
               }
               // .add {}
            }
            .price {
               font-size: 1.25rem;
               font-weight: 600;
               color: $background-1;
            }
            .remove {
               font-size: .6rem;
               color: $color-6;
               padding: .05rem .2rem;
               border-radius: 4px;
               background: $background-10;
               transition: $trans3;
               cursor: pointer;
               &:hover {
                  background: $background-10a;
               }
            }
         }
         .cartTotals {
            .contents {
               border-radius: 4px;
               border: 1px solid $color-4;
               .title {
                  font-size: 1.2rem;
                  color: $color-1;
               }
               .orderValue {
                  font-size: .85rem;
                  color: $color-3;
               }
               .amount {
                  font-size: 1rem;
                  font-weight: 600;
                  color: $color-1;
               }
               .total {
                  font-size: 1rem;
                  color: $color-3;
               }
            }
            .continue {
               font-size: 1rem;
               color: $white;
               --value: .7rem;
               padding-top: var(--value);
               padding-bottom: var(--value);
               border-radius: 4px;
               background: $background-1;
               transition: $trans3;
               &:hover {
                  background: $background-1a;
               }
            }
         }
      }

      .billingDetails {
         border-radius: 4px;
         background: $white;
         // animation: $slide;
         .__form {
            width: 75%;
            @media screen and (max-width: 767px) {
               width: 100%;
            }
            label {
               font-size: .9rem;
               color: $color-3;
            }
            input, textarea {
               font-size: .8rem;
               color: $color-1;
               padding: .5rem 1.2rem;
               border-radius: 4px;
               border: 1px solid $color-4;
               &::placeholder {
                  color: $color-4;
               }
            }
            .submit {
               font-size: .8rem;
               color: $white;
               padding: .7rem 2.5rem;
               border-radius: 4px;
               background: $background-1;
               transition: $trans3;
               &:hover {
                  background: $background-1a;
               }
            }
         }
         .price {
            font-size: 1.25rem;
            font-weight: 600;
            color: $background-1;
         }
         p {
            font-size: 1.1rem;
            color: $color-1;
         }
         .submit {
            font-size: 1rem;
            color: $white;
            padding: .7rem 2.5rem;
            border-radius: 4px;
            background: $background-1;
            transition: $trans3;
            &:hover {
               background: $background-1a;
            }
         }
      }
   }
}
