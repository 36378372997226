@import '../../../../variables';

.sectionThree {
   background: $background-3;
   .content {
      h6 {
         color: $color-1;
         font-size: 1.2rem;
      }
      h1 {
         color: $color-1;
         font-size: 3.1rem;
         .bubbles {
            top: -100%;
            right: 0;
         }
      }
   }
   @media screen and (max-width: 767px) {
      .content {
         h6 {
            font-size: .9rem;
         }
         h1 {
            font-size: 1.8rem;
         }
      }
   }
}