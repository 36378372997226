@import '../../../../variables';

.sectionFive {
   background: $background-11;
   .content {
      .banner {
         .reserve {
            .iconWrapper {
               bottom: 20%;
               right: -12%;
               .icon {
                  transform: scale(.7);
               }
            }
            .title {
               font-size: 3.2rem;
               font-weight: 600;
               color: $color-1;
               line-height: 1.2;
            }
            @media screen and (max-width: 991px) {
               .title {
                  font-size: 2.25rem;
               }
            }
            .subTitle {
               font-size: 1.17rem;
               color: $color-3;
               line-height: 1.8;
            }
            .reservations {
               font-size: .9rem;
               color: $white;
               padding: .6rem 1.7rem;
               background: $background-1;
               border-radius: 3px;
               transition: $trans3;
               &:hover {
                  background: $background-1a;
               }
            }
            .img {
               --value: 33.4rem;
               width: var(--value);
               height: var(--value);
               img {
                  border-radius: 4px;
               }
            }
         }
         @media screen and (max-width: 767px) {
            .reserve {
               .iconWrapper {
                  bottom: 5%;
                  right: -12%;
                  .icon {
                     transform: scale(.5);
                  }
               }
               .title {
                  font-size: 1.9rem;
                  text-align: center;
                  // line-height: 1.2;
               }
               .subTitle {
                  font-size: 1rem;
                  text-align: center;
                  line-height: 1.5;
               }
               button {
                  font-size: .9rem;
                  padding: .4rem 1.4rem;
                  margin: 0 auto;
               }
               .img {
                  --value: 13.4rem;
               }
            }
            
         }
      }
   }
}