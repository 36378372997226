@import './variables';

/*=========== Fonts ==============*/
@font-face {
   font-family: 'ProductSans';
   font-style: normal;
   font-weight: 500;
   src: url('./_assets/fonts/Product\ Sans\ Regular.ttf');
}
@font-face {
   font-family: 'ProductSans';
   font-style: normal;
   font-weight: 600;
   src: url('./_assets/fonts/Product\ Sans\ Bold.ttf');
}
@font-face {
   font-family: 'FuturaMdBt';
   font-style: normal;
   font-weight: 500;
   src: url('./_assets/fonts/FuturaMediumBT.ttf');
}
@font-face {
   font-family: 'FuturaMdBt';
   font-style: normal;
   font-weight: 600;
   src: url('./_assets/fonts/FuturaBoldBT.ttf');
}

html {
   margin: 0;
   padding: 0;
   width: 100%;
   height: 100%;

   body {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      font-size: 1rem;
      // font-family: $font;
      background: #fff;
      box-sizing: border-box;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;

      & > * {
         margin: 0;
         padding: 0;
         box-sizing: border-box;
      }

      #root {
         width: 100%;
         height: 100%;
         display: flex;
         flex-direction: column;
         position: relative;
         @media (min-width: 992px) {
            .container-xl, .container-lg, .container-md, .container-sm, .container {
               max-width: 1200px;
            }
         }
      }

      &::-webkit-scrollbar {
         width: 6px; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: $color-1;
         border-radius: 2px;
      }
      @media screen and (max-width: 576px) {
         &::-webkit-scrollbar {
            width: 0; /* Safari and Chrome */
         }
      }
      .shimmer {
         background: linear-gradient(to right, #4F4F4F 8%, #4F4F4F 18%, #4F4F4F 33%) !important;
      }
   }
}

// @media screen and (max-width: 678px) {
//    h1, h2, h3, h4, h5, h6 {
//       font-size: 1.2rem !important;
//    }
//    p, span, input, .input, textarea, button, label, .label {
//       font-size: .9rem !important;
//    }
// }

h1, h2, h3, h4, h5, h6 {
   margin: 0;
   padding: 0;
   font-family: $font2;
}

p, span, small, sup, sub {
   margin: 0;
   padding: 0;
   font-family: $font1;
}

ul, ol {
   margin: 0;
   padding: 0;
   list-style-type: none;
   font-family: $font1;
}

a {
   text-decoration: none;
   text-decoration-skip-ink: auto;
   font-family: $font1;
}

img {
   object-fit: cover;
}

input, textarea {
   &:focus, &:active {
      &::placeholder {
         opacity: 0.5;
      }
   }
}

textarea {
   resize: none;
}

button {
   border: none;
   background: transparent;
}

button, input, textarea {
   outline: none !important;
   font-family: $font1;
}
