@import './variables';

.app {
   .wrapper {
      position: relative;
      margin-top: 5rem;
   }
   @media screen and (max-width: 767px) {
      .wrapper {
         margin-top: 3.5rem;
      }
   }
}