@import '../../variables';

.blog {
   h4 {
      font-size: 2rem;
      color: $color-1;
   }
   .subTitle {
      font-size: 1.1rem;
   }
}