@import '../../../../variables';

.reservations {
   background: #FFFFFF;
   box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
   border-radius: 8px;
   h6 {
      font-size: .95rem;
      color: $background-1;
   }
   h4 {
      font-weight: 600;
      color: $color-1;
   }
   .__form {
      label {
         font-size: 1rem;
         color: $color-3;
      }
      input, textarea, select {
         font-size: .9rem;
         color: $color-1;
         padding: .5rem 1.2rem;
         border-radius: 4px;
         border: 1px solid $color-4;
         &::placeholder {
            color: $color-4;
         }
      }
      select {
         padding: .65rem 1.2rem;
         option {
            padding: .65rem 1.2rem !important;
            transition: .3s;
            &:hover {
               background: #f5390a !important;
            }
         }
      }
      .error-text {
         color: #f5390a!important;
         font-weight: 400;
         font-size: 14px;        
      }
      .submit {
         font-size: .9rem;
         color: $white;
         padding: .7rem 2.5rem;
         border-radius: 4px;
         background: $background-1;
         transition: $trans3;
         &:hover {
            background: $background-1a;
         }
      }
   }
}
