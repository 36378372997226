@import '../../variables';

.__contact {
   height: 20.5rem;
   background: $background-1;
   .title {
      font-size: 2.5rem;
      font-weight: 600;
      color: $white;
      position: relative;
      display: flex;
      justify-content: center;
      &::before {
         content: '';
         position: absolute;
         margin: 0 auto;
         top: -1rem;
         height: 7px;
         width: 20%;
         background: $white;
      }
   }
   @media screen and (max-width: 767px) {
      .title {
         font-size: 1.5rem;
      }
   }
}

.contactSectionTwo {
   background: $background-3;
   .content {
      .contactDetails, .yourMessage {
         background: #FFFFFF;
         box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
         border-radius: 8px;
      }
      .contactDetails {
         // h4 {}
         ul {
            li {
               p, a {
                  font-size: 1rem;
                  color: $color-3;
               }
               .head {
                  font-size: 1.3rem;
                  color: $color-7;
               }
               a {
                  text-decoration: underline;
               }
               .icon, .icon2 {
                  display: flex;
                  align-items: center;
                  justify-content: center;
               }
               .icon {
                  --value: 1.2rem;
                  width: var(--value);
                  height: var(--value);
                  // background: #777;
               }
               .icon2 {
                  width: 2rem;
               }
            }
         }
      }
      .yourMessage {
         // h4 {}
         .__form {
            label {
               font-size: 1rem;
               color: $color-3;
            }
            input, textarea {
               font-size: .9rem;
               color: $color-1;
               padding: .5rem 1.2rem;
               border-radius: 4px;
               border: 1px solid $color-4;
               &::placeholder {
                  color: $color-4;
               }
            }
            .submit {
               font-size: .9rem;
               color: $white;
               padding: .7rem 2.5rem;
               border-radius: 4px;
               background: $background-1;
               transition: $trans3;
               &:hover {
                  background: $background-1a;
               }
            }
         }
      }
   }
}
