@import '../../variables';

.notice {
   position: fixed;
   top: 6rem;
   right: 2rem;
   background: #fff;
   box-shadow: 0px 6px 22px rgba(85, 108, 213, 0.12);
   border-radius: 3px;
   z-index: 2000;
   // height: 50px;
   width: fit-content;
   max-width: 500px;

   &.success {
      background-color: #ACE4B9;
   }
   &.error {
      background-color: #F51616;
   }
   .img {
      --value: 4rem;
      width: var(--value);
      height: var(--value);
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 6px 22px rgba(85, 108, 213, 0.12);
   }
   span {
      font-size: .85rem;
      color: $color-3;
      max-width: 300px;
   }
   a {
      font-size: .85rem;
      text-decoration: underline;
      color: $background-1;
      transition: $trans3;
      &:hover {
         color: $background-1a;
      }
   }
   @media screen and (max-width: 767px) {
      max-width: 90vw;
      right: 0;
      left: 1rem;
      span {
         max-width: 42vw;
      }
   }
}
