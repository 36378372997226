@import '../../variables';

.blogCard {
   --height: 28.5rem;
   .img {
      width: 100%;
      height: var(--height);
      border-radius: 4px;
      overflow: hidden;
      img {
         transition: $trans3;
      }
      &:hover img {
         transform: scale(1.03);
      }
   }
   .blogPost {
      bottom: 0;
      width: 100%;
      .content {
         width: 85%;
         height: calc(var(--height) / 2);
         margin: 0 auto;
         background: $background-4;
         border-radius: 5px 5px 0px 0px;
         p {
            color: $white;
         }
         .title {
            font-size: 1.05rem;
            font-weight: 600;
         }
         .post {
            font-size: .92rem;
            line-height: 1.5;
            // text-align: justify;
            text-overflow: ellipsis;
            // width: 251.19px;
            width: 15.7rem;
            -webkit-box-orient: vertical;
            display: block;
            display: -webkit-box;
            overflow: hidden !important;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
         }
         @media screen and (max-width: 1170px) {
            .post {
               width: 100%;
            }
         }
         button {
            font-size: .8rem;
            color: $white;
            border-radius: 4px;
            padding: .4rem .8rem;
            border: 1px solid $white;
            transition: $trans3;
            &:hover {
               border: 1px solid transparent;
               background: $white;
               color: $color-1;
            }
         }
         .date {
            font-size: .8rem;
            color: $white;
         }
      }
   }
}

@media screen and (max-width: 767px) {
   .blogCard {
      --height: 23rem;
      button {
         font-size: .6rem !important;
         padding: .2rem .4rem !important;
      }
   }
}
