@import '../../variables';

.__navbar {
   height: 5rem;
   background: transparent;
   transition: $trans2;
   z-index: 900;
   &.whiteBg {
      background: $white !important;
      // box-shadow: 0px -12px 25px rgba(0, 0, 0, .5);
      box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
   }
   .content {
      .logo, .action {
         width: 30%;
      }
      .logo {
         .img {
            width: 5.5rem;
            // height: 3rem;
         }
      }
      .menu {
         a {
            font-size: 1rem;
            color: $color-1;
            // padding-bottom: .5rem;
            position: relative;
            transition: $trans3;
            &:hover, &.active {
               color: $background-1;
            }
            &::before {
               content: '';
               height: 2px;
               position: absolute;
               border-radius: 4px;
               width: 0;
               bottom: 0;
               left: 0;
               top: 98%;
               background: $background-1;
               transition: $trans3;
            }
            &:hover::before, &.active::before {
               width: 100%;
            }
            &.color {
               color: $white;
               &:hover, &.active {
                  color: $background-1;
               }
            }
            &.back {
               color: $color-3;
               &:hover, &.active {
                  color: $background-1;
               }
            }
         }
      }
      .action {
         .cart {
            --value: 1.2rem;
            width: var(--value);
            height: var(--value);
            transition: $trans2;
            .cartIcon {
               color: $color-3;
               transition: $trans2;
               &.color {
                  color: $white;
               }
               &.back {
                  color: $color-3;
               }
            }
            &.active .cartIcon, &:hover .cartIcon {
               color: $background-1;
            }
            .no {
               top: -.5rem;
               right: -.5rem;
               --value: 1rem;
               width: var(--value);
               height: var(--value);
               font-size: .6rem;
               color: $white;
               border-radius: 50%;
               overflow: hidden;
               background: $background-1;
            }
         }
         .burgerIcon {
            color: $color-3;
            cursor: pointer;
            transform: scale(1);
            &.color {
               color: $white;
            }
            &.back {
               color: $color-3;
            }
         }
         button {
            font-size: .9rem;
            color: $white;
            padding: .6rem 1.7rem;
            background: $background-1;
            border-radius: 3px;
            transition: $trans3;
            &:hover {
               background: $background-1a;
            }
         }
      }
   }
   .mobileMenu {
      display: none;
   }
   &.hidden {
      display: none;
      pointer-events: none;
      & > * {
         pointer-events: none;
      }
   }
}

@media screen and (max-width: 767px) {
   .__navbar {
      height: 3.5rem;
      .content {
         .logo, .action {
            width: fit-content;
         }
         .logo {
            .img {
               width: 3.5rem;
               height: 2rem;
            }
         }
      }
      .mobileMenu {
         top: 0;
         left: 0;
         transition: $trans1;
         background: #fff;
         box-shadow: 0px -4px 25px rgba(0, 0, 0, .5);
         width: 100%;
         z-index: 1050;
         &.showMenu {
            display: block;
            animation: $slide;
         }
         .content {
            button {
               font-size: .9rem;
               color: $white;
               padding: .6rem 1.7rem;
               background: $background-1;
               border-radius: 3px;
               transition: $trans3;
               &:hover {
                  background: $background-1a;
               }
            }
         }
      }
   }
}
