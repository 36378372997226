@import '../../../../variables';

.aboutSectionFour {
   background: $background-3;
   .content {
      .bgImg {
         top: 0;
         bottom: 0;
         right: 0;
         left: 0;
         height: 100%;
         // z-index: -1;
      }
      .bg {
         border-radius: 4px;
         background: rgba(0, 0, 0, .9);
         // z-index: 1;
         .bgIcon {
            top: -1.9rem;
            right: 1.75rem;
            transform: scale(.7);
         }
         h2 {
            font-size: 2.5rem;
            color: $white;
         }
         p {
            font-size: 1rem;
            line-height: 2.7;
            color: $white;
            text-align: justify;
         }
         .imgBg {
            width: 100%;
            height: 33.2rem;
            border-radius: 8px;
            overflow: hidden;
         }
         // .img {
         //    width: 27.09rem;
         //    height: 27.1rem;
         //    border-radius: 4px;
         //    overflow: hidden;
         // }
      }
   }
   @media screen and (max-width: 767px) {
      .content {
         .bgIcon {
            transform: scale(.5);
         }
         .bg {
            .imgBg {
               height: 22rem;
            }
         }
         h2 {
            font-size: 2rem;
         }
         p {
            line-height: 2;
         }
      }
   }
}
