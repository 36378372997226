@import '../../variables';

.__footer {
   height: 25rem;
   background: $background-2;
   .content {
      h4 {
         font-size: 1.35rem;
         color: $white;
      }
      p {
         font-size: .9rem;
         color: $color-2;
         // font-weight: 300;
      }
      .socialMedia {
         li {
            --value: 2rem;
            width: var(--value);
            height: var(--value);
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid $white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $trans3;
            &:hover {
               border: 1px solid transparent;
               background: $white;
               
            }
            .icon {
               color: $white;
               transform: scale(.8);
            }
            &:hover .icon {
               color: $black;
            }
         }
      }
      .lists {
         // column-count: 2;
         text-align: center;
         li {
            margin-bottom: 1.5rem;
            a {
               font-size: .9rem;
               color: $color-2;
               transition: $trans4;
               &:hover {
                  color: $color-2a;
               }
            }
         }
      }
      .contact {
         li {
            margin-bottom: 1.5rem;
            @media screen and (max-width: 767px) {
               margin-bottom: 1rem;
            }
            p, a {
               font-size: .9rem;
               color: $color-2;
            }
            a {
               text-decoration: underline;
            }
            .icon {
               --value: 1.2rem;
               width: var(--value);
               height: var(--value);
               // background: #777;
               display: flex;
               align-items: center;
               justify-content: center;
            }
            .icon2 {
               width: 2rem;
               display: flex;
               align-items: center;
               justify-content: center;
            }
         }
      }
      footer {
         font-size: .9rem;
         color: $color-2;
         span {
            font-size: 1.2rem;
            padding-right: .3rem;
         }
      }
   }
   &.hidden {
      display: none;
      pointer-events: none;
      & > * {
         pointer-events: none;
      }
   }
}

@media screen and (max-width: 767px) {
   .__footer {
      height: fit-content;
   }
}
